import React from "react";
import { Helmet } from "react-helmet";

import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import globalStyle from "../utils/globalStyle";

import images from "../images/facultyImages";

import CITELogoBig from "../assets/img/logo512.png";

import { ReactComponent as ITEngineeringImage } from "../assets/svg/it-engineering-image.svg";

import Header from "../components/Header";
import HeadBox from "../components/HeadBox";
import Footer from "../components/Footer";
import ScrolllToTopButton from "../components/ScrollToTopButton";

const style = {
  ...globalStyle,
  root: {
    backgroundColor: (theme) => theme.palette.background.default,
  },

  section1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "92px 5px",
  },
  logoBig: {
    height: {
      xs: 160,
      sm: 180,
      md: 200,
    },
    marginTop: 1,
  },
  heading1: {
    fontSize: {
      xs: 18,
      sm: 24,
      md: 32,
    },
    textAlign: "center",
    marginTop: 10,
    fontWeight: 700,
  },
  heading2: {
    fontSize: {
      xs: 16,
      sm: 18,
      md: 24,
    },
    textAlign: "center",
    marginTop: 1,
    fontWeight: 600,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  svgImage1: {
    height: {
      xs: 60,
      sm: 100,
      md: 150,
    },
    color: (theme) => theme.palette.primary.main,
  },

  section2: {
    padding: {
      xs: "64px 0px",
      sm: "72px 0px",
      md: "92px 0px",
    },
    backgroundColor: (theme) => theme.palette.primary.main,
  },

  heading3: {
    fontSize: {
      xs: 24,
      sm: 36,
      md: 48,
    },
    textAlign: "Center",
    fontWeight: 700,
    color: (theme) => theme.palette.common.white,
  },

  section3: {
    padding: 5,
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: {
      xs: "40px 5px",
      sm: 10,
    },
  },
  button: {
    ...globalStyle.h3,
    textTransform: "none",
    fontWeight: 500,
    padding: "16px 32px",
  },
};

export default function Index() {
  return (
    <Box sx={style.root}>
      <Helmet>
        <title>BulSU - CITE</title>
        <meta
          name="description"
          content="COLLEGE OF INFORMATION TECHNOLOGY AND ENGINEERING ... Bulacan State University - Bustos Campus"
        />
      </Helmet>

      <Header />

      <Box sx={style.section1}>
        <Box
          component="img"
          src={CITELogoBig}
          alt="cite_logo"
          sx={style.logoBig}
        />
        <Typography variant="h1" sx={style.heading1}>
          COLLEGE OF INFORMATION TECHNOLOGY AND ENGINEERING
        </Typography>
        <Typography variant="h2" sx={style.heading2}>
          Bulacan State University - Bustos Campus
        </Typography>
      </Box>

      <Box sx={style.imageContainer}>
        <Box sx={style.svgImage1}>
          <ITEngineeringImage style={{ height: "inherit", color: "inherit" }} />
        </Box>
      </Box>
      <Box sx={style.section2}>
        <Typography variant="h1" sx={style.heading3}>
          Bustos Campus Administrators
        </Typography>
      </Box>

      <Box sx={style.section3}>
        {images.campus.map((data, i) => (
          <HeadBox row={i} data={data} key={i} />
        ))}
      </Box>

      <Box sx={style.buttonContainer}>
        <Button
          component={Link}
          to="/cite-faculty"
          variant="contained"
          color="primary"
          sx={style.button}
        >
          View CITE Faculty Members
        </Button>
      </Box>

      <ScrolllToTopButton />
      <Footer />
    </Box>
  );
}

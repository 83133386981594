import React, { useEffect } from "react";
import { Fab, useScrollTrigger } from "@mui/material";

import { ReactComponent as ArrowIcon } from "../assets/svg/arrow-up-solid.svg";

const style = {
  fab: {
    position: "fixed",
    bottom: "20px",
    right: "30px",
    color: (theme) => theme.palette.background.default,
    backgroundColor: (theme) => theme.palette.primary.light,
    "&:hover": {
      color: (theme) => theme.palette.background.default,
      backgroundColor: (theme) => theme.palette.primary.main,
    },
  },
};
export default function ScrollToTopButton() {
  const trigger = useScrollTrigger({ disableHysteresis: true });

  useEffect(() => {
    console.log(trigger);
  }, [trigger]);

  return (
    <Fab sx={{ ...style.fab, display: trigger ? "flex" : "none" }} href="#top">
      <ArrowIcon style={{ width: 20, height: 20, color: "inherit" }} />
    </Fab>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton";

const style = {
  section: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "64px 5px",
  },
  text1: {
    textAlign: "center",
    fontSize: {
      xs: 82,
      sm: 100,
      md: 124,
    },
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    fontSize: {
      xs: 32,
      sm: 44,
      md: 64,
    },
    fontWeight: 500,
  },
  text3: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: 500,
  },
  button: {
    marginTop: 5,
  },
};

export default function NotFound() {
  return (
    <Box>
      <Helmet>
        <title>BulSU - CITE - 404 Not Found</title>
        <meta
          name="description"
          content="COLLEGE OF INFORMATION TECHNOLOGY AND ENGINEERING ... 404 Not Found"
        />
      </Helmet>
      <Header />
      <Box sx={style.section}>
        <Typography variant="h1" sx={style.text1}>
          404
        </Typography>
        <Typography variant="h2" sx={style.text2}>
          There's NOTHING here...
        </Typography>
        <Typography variant="subtitle1" sx={style.text3}>
          ..maybe the page you're loooking for is not found or never existed.
        </Typography>
        <Button component={Link} to="/" variant="contained" sx={style.button}>
          Back to home
        </Button>
      </Box>

      <ScrollToTopButton />
      <Footer />
    </Box>
  );
}

import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import globalStyle from "../utils/globalStyle";

const style = {
  ...globalStyle,
  root: {
    padding: "32px 5px",
  },
  image: {
    height: {
      xs: 300,
      sm: 350,
      md: 400,
    },
  },
  profName: {
    fontSize: {
      xs: globalStyle.h3.fontSize,
      sm: globalStyle.h2.fontSize,
      md: globalStyle.h1.fontSize,
    },
    marginTop: 5,
    fontWeight: 600,
  },
  profDesc: {
    fontSize: {
      xs: globalStyle.h4.fontSize,
      sm: globalStyle.h3.fontSize,
      md: globalStyle.h2.fontSize,
    },
    color: (theme) => theme.palette.primary.main,
  },
};

export default function HeadBox({ row, data }) {
  return (
    <Box sx={style.root}>
      <Grid
        container
        justifyContent="center"
        spacing={{ xs: 1, sm: 10, md: 20 }}
        direction={row % 2 === 0 ? "row" : "row-reverse"}
      >
        <Grid
          sm
          item
          sx={{
            display: "flex",
            justifyContent: row % 2 === 0 ? "flex-end" : "flex-start",
          }}
        >
          <Box component="img" src={data.img} alt="dean" sx={style.image} />
        </Grid>
        <Grid sm item>
          <Typography
            variant="h1"
            sx={style.profName}
            textAlign={row % 2 === 0 ? "left" : "right"}
          >
            {data.name}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={style.profDesc}
            textAlign={row % 2 === 0 ? "left" : "right"}
          >
            {data.designation}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

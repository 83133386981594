import DEAN from "../assets/img/faculty/dean.png";
import EDITHA from "../assets/img/faculty/editha.png";

import LYN from "../assets/img/faculty/lyn.png";
import MALANG from "../assets/img/faculty/malang.png";

//IT
import AJ from "../assets/img/faculty/aj.png";
import BONGGA from "../assets/img/faculty/bongga.png";
import BONI from "../assets/img/faculty/boni.png";
import BOTON from "../assets/img/faculty/boton.png";
import ITSJNATHAN from "../assets/img/faculty/itsjnathan.png";
import JAISCEE from "../assets/img/faculty/jaiscee.png";
import JERA from "../assets/img/faculty/jera.png";
import JULIUS from "../assets/img/faculty/julius.png";
import LUCKY from "../assets/img/faculty/lucky.png";
import LUIS from "../assets/img/faculty/luis.png";
import MICHAEL from "../assets/img/faculty/michael.png";
import NESTOR from "../assets/img/faculty/nestor.png";
import ROMELSA from "../assets/img/faculty/romelsa.png";
import ROMMEL from "../assets/img/faculty/rommel.png";
import RYAN from "../assets/img/faculty/ryan.png";
import SEVERINO from "../assets/img/faculty/severino.png";
import THEA from "../assets/img/faculty/thea.png";
import THESS from "../assets/img/faculty/thess.png";
import WENDY from "../assets/img/faculty/wendy.png";
import DONA from "../assets/img/faculty/dona.png";
import JEN from "../assets/img/faculty/jen.png";
import KYLE from "../assets/img/faculty/kyle.png";
import RAEV from "../assets/img/faculty/raev.png";
import RAVEE from "../assets/img/faculty/ravee.png";
import VRANNDAWN from "../assets/img/faculty/vranndawn.png";

import ANTHONY from "../assets/img/faculty/anthony.png";
import ARNORLD from "../assets/img/faculty/arnold.png";
import JAN from "../assets/img/faculty/jan.png";
import CUNANAN from "../assets/img/faculty/cunanan.png";
import PAULA from "../assets/img/faculty/paula.png";

const images = {
  campus: [
    {
      img: DEAN,
      name: "DR. CIRIACO M. GARCIA",
      designation: "Campus Dean",
    },
    {
      img: EDITHA,
      name: "DR. EDITHA DE REGLA",
      designation: "Campus Secretary",
    },
  ],
  cite_head: [
    {
      img: MALANG,
      name: "DR. BERNANDINO P. MALANG",
      designation: "Department Head",
    },
    {
      img: LYN,
      name: "MS. LYN M. DALISAYMO",
      designation: "Program Chair - Information Technology",
    },
    {
      img: RAVEE,
      name: "ENGR. JOSE RAVENAL S. OCAMPO",
      designation: "Program Chair - Engineering",
    },
    {
      img: RAEV,
      name: "ENGR. RAEVINOR GONZALES",
      designation: "Head, Student Affairs",
    },
    {
      img: KYLE,
      name: "MS. MARYGOLD PAULINO",
      designation: "Head, NAPA",
    },
  ],
  cite_it_faculty: [
    {
      img: ITSJNATHAN,
      name: "Mr. Jonathan J. Hipolito",
    },
    {
      img: AJ,
      name: "Mr. Albert Jason Reyes",
    },
    {
      img: ANTHONY,
      name: "Mr. Anthony U. Conception",
    },
    {
      img: MALANG,
      name: "Dr. Bernandino P. Malang",
      designation: "Department Head",
    },

    {
      img: BOTON,
      name: "Ms. Maria Cristina S. Boton",
    },

    {
      img: JAISCEE,
      name: "Ms. Jocelle Buñing-Cruz",
    },
    {
      img: LYN,
      name: "Ms. Lyn M. Dalisaymo",
      designation: "Program Chair - Information Technology",
    },
    {
      img: MICHAEL,
      name: "Mr. Michael Agustin",
    },

    {
      img: ROMELSA,
      name: "Ms. Romelsa D. Romero",
    },
    {
      img: ROMMEL,
      name: "Mr. Rommel Pabustan",
    },
    {
      img: RYAN,
      name: "Mr. Ryan Ragos",
    },
    {
      img: THEA,
      name: "Ms. Alaina Thea Villanueva",
    },
    {
      img: THESS,
      name: "Ms. Teresita G. Dela Cruz",
    },
  ],
  cite_engineering: [
    {
      img: LUIS,
      name: "Mr. Luis Mallari",
    },
    {
      img: ARNORLD,
      name: "Engr. Arnold Sanchez",
    },
    {
      img: SEVERINO,
      name: "Engr. Severino Salvador",
    },
    {
      img: RAVEE,
      name: "Engr. Jose Ravenal S. Ocampo",
      designation: "Program Chair - Engineering",
    },
    {
      img: JAN,
      name: "Mr. Januarius Cruz",
    },
    {
      img: LUCKY,
      name: "Engr. Lucky Gem Asibuque",
    },
    {
      img: CUNANAN,
      name: "Mr. Christopher Cunanan",
    },
    {
      img: RAEV,
      name: "Engr. Raevinor Gonzales",
      designation: "Head, Student Affairs",
    },
    {
      img: WENDY,
      name: "Ms. Wendy E. Ollero",
    },
  ],
  cite_gen_ed: [
    {
      img: NESTOR,
      name: "Mr. Nestor Santos",
    },
    {
      img: PAULA,
      name: "Ms. Paula Valencia",
    },
    {
      img: BONGGA,
      name: "Ms. Cristy Joy Bongga",
    },
    {
      img: BONI,
      name: "Mr. Bonifacio Esguerra",
    },
    {
      img: JERA,
      name: "Ms. Jeramae L. Meneses",
    },
    {
      img: JULIUS,
      name: "Mr. Julius Reyes",
    },
    {
      img: DONA,
      name: "Dr. Donnabelle Sarmiento",
    },
  ],
  cite_napa: [
    {
      img: KYLE,
      name: "Ms. Marygold Paulino",
      designation: "Head, NAPA",
    },
    {
      img: JEN,
      name: "Ms. Jenny De Leon",
    },
    {
      img: VRANNDAWN,
      name: "Mr. Vranndawn De Leon",
    },
  ],
};

export default images;

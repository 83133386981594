import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material";
import theme from "../utils/theme";
import CssBaseline from "@mui/material/CssBaseline";

import { useSelector } from "react-redux";

import index from "../pages";
import CITEFaculty from "../pages/CITEFaculty";
import DownloadableForms from "../pages/DownloadableForms";
import Announcements from "../pages/Announcements";

import NotFound from "../pages/404NotFound";

export default function Routes() {
  const ui = useSelector((state) => state.ui);
  const THEME = createTheme(theme(ui.isDarkMode));
  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route component={index} path="/" exact />
          <Route component={CITEFaculty} path="/cite-faculty" exact />
          <Route
            component={DownloadableForms}
            path="/downloadable-forms"
            exact
          />
          <Route component={Announcements} path="/announcements" exact />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

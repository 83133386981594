import React from "react";

import { Box, Typography, Grid, IconButton, Link } from "@mui/material";
import globalStyle from "../utils/globalStyle";

import CITELogo from "../assets/img/logo192.png";

import { ReactComponent as FacebookIcon } from "../assets/svg/facebook-f-brands.svg";
import { ReactComponent as EmailIcon } from "../assets/svg/envelope-regular.svg";

const style = {
  ...globalStyle,
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "64px 5px",
    backgroundColor: (theme) => theme.palette.footer,
  },
  logo: {
    height: 40,
  },
  footerText1: {
    ...globalStyle.h4,
    color: (theme) => theme.palette.common.white,
    marginTop: 5,
  },
  footerText2: {
    ...globalStyle.h3,
    color: (theme) => theme.palette.common.white,
    marginTop: 3,
    textAlign: "center",
  },
  footerText3: {
    ...globalStyle.h4,
    color: (theme) => theme.palette.common.white,
    marginTop: 1,
    textAlign: "center",
  },
  socialIcons: {
    color: (theme) => theme.palette.common.white,
    border: 2,
    borderColor: (theme) => theme.palette.primary.light,
    "&:hover": {
      border: 2,
      backgroundColor: (theme) => theme.palette.common.white,
      color: (theme) => theme.palette.primary.main,
    },
  },
  itsjnathan: {
    color: "inherit",
    fontSize: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export default function Footer() {
  return (
    <Box component="footer" sx={style.footer}>
      <Box component="img" src={CITELogo} alt="cite_logo" sx={style.logo} />
      <Typography sx={style.footerText2}>
        College of Information Technology and Engineering
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ paddingTop: 5 }}
      >
        <Grid item>
          <IconButton
            color="secondary"
            sx={style.socialIcons}
            size="large"
            component={Link}
            href="https://www.facebook.com/cite.bulSU"
            target="_blank"
            rel="noopener"
          >
            <FacebookIcon style={{ width: 20, height: 20, color: "inherit" }} />
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton
            color="secondary"
            sx={style.socialIcons}
            size="large"
            component={Link}
            href="#"
            // target="_blank"
            // rel="noopener"
          >
            <EmailIcon style={{ width: 20, height: 20, color: "inherit" }} />
          </IconButton>
        </Grid>
      </Grid>
      <Typography sx={style.footerText1}>BulSU Bustos - CITE © 2021</Typography>

      <Typography sx={style.footerText3}>
        Powered by ©{" "}
        <Link
          underline="none"
          href="https://itsjnathan.com/"
          target="_blank"
          rel="noopener"
          sx={style.itsjnathan}
        >
          itsjnathan.com
        </Link>
      </Typography>
    </Box>
  );
}

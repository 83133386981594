import React, { useEffect, useState } from "react";

import {
  AppBar,
  Toolbar,
  Link,
  Box,
  Typography,
  IconButton,
  useScrollTrigger,
  Slide,
} from "@mui/material";
import { Link as RLink, useLocation } from "react-router-dom";

import globalStyle from "../utils/globalStyle";

import CITELogo from "../assets/img/logo192.png";
import { ReactComponent as LightIcon } from "../assets/svg/sun-solid.svg";
import { ReactComponent as DarkIcon } from "../assets/svg/moon-solid.svg";
import { ReactComponent as MenuIcon } from "../assets/svg/bars-solid.svg";
import { ReactComponent as CloseIcon } from "../assets/svg/times-solid.svg";

import { useSelector, useDispatch } from "react-redux";
import { toggleTheme, getTheme } from "../redux/actions/uiAction";

const style = {
  ...globalStyle,
  toolbar: {
    padding: {
      xs: "16px 8px",
      sm: "24px 24px",
    },
    display: "block",
  },
  mainToolBarContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center"
  },
  logo: {
    height: 40,
  },
  websiteName1: {
    ...globalStyle.marginLeft3,
    ...globalStyle.h2,
    fontWeight: 500
  },
  themeIcon: {
    color: (theme) => theme.palette.primary.main,
    border: 2,
    borderColor: (theme) => theme.palette.primary.main,
    "&:hover": {
      border: 2,
      backgroundColor: (theme) => theme.palette.primary.main,
      color: (theme) => theme.palette.common.white,
      borderColor: (theme) => theme.palette.primary.main,
    },
  },
  navContainer: {
    display: {
      xs: "none",
      md: "flex",
    },
    alignItems: "center",
  },
  menuHamburgerContainer: {
    display: {
      xs: "block",
      md: "none",
    },
  },
  drawerItems: {
    display: {
      xs: "flex",
      md: "none",
    },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  navItem: {
    ...globalStyle.h3,
    "&:hover": {
      color: (theme) => theme.palette.secondary.main,
    },
    marginRight: (theme) => ({
      md: theme.spacing(3),
    }),
    marginBottom: (theme) => ({
      xs: theme.spacing(2),
      md: 0,
    }),
  },
  navItemSelected: {
    ...globalStyle.h2,
    textDecoration: "underline",
    fontWeight: 700,
    marginRight: (theme) => ({
      md: theme.spacing(3),
    }),
    marginBottom: (theme) => ({
      xs: theme.spacing(2),
      md: 0,
    }),
  },
};

export default function Header() {
  const trigger = useScrollTrigger();
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(pathname);
  }, [pathname]);

  useEffect(() => {
    dispatch(getTheme());
  }, [dispatch]);

  const [state, setstate] = useState({
    showDrawer: false,
  });

  const toggleThemeFunc = () => {
    dispatch(toggleTheme(!ui.isDarkMode));
  };

  const toggleDrawer = () => {
    setstate((prevItem) => ({ showDrawer: !prevItem.showDrawer }));
  };
  return (
    <Slide appear={false} direction="down" in={!trigger || state.showDrawer}>
      <AppBar position="sticky" color="inherit" sx={style.removeShadow}>
        <Toolbar sx={style.toolbar}>
          <Box sx={style.mainToolBarContainer}>
            <Link
              component={RLink}
              to="/"
              underline="none"
              sx={style.logoContainer}
            >
              <Box
                component="img"
                src={CITELogo}
                alt="cite_logo"
                sx={style.logo}
              />
              <Typography sx={style.websiteName1}>BulSU CITE</Typography>
            </Link>

            <Box component="span" sx={style.flexGrow} />
            <Box sx={style.navContainer}>
              <Link
                component={RLink}
                to="/"
                underline="none"
                sx={pathname === "/" ? style.navItemSelected : style.navItem}
              >
                Home
              </Link>
              <Link
                component={RLink}
                to="/cite-faculty"
                underline="none"
                sx={
                  pathname === "/cite-faculty"
                    ? style.navItemSelected
                    : style.navItem
                }
              >
                CITE Faculty
              </Link>
              <Link
                component={RLink}
                to="/downloadable-forms"
                underline="none"
                sx={
                  pathname === "/downloadable-forms"
                    ? style.navItemSelected
                    : style.navItem
                }
              >
                Downloadable Forms
              </Link>
              <Link
                component={RLink}
                to="/announcements"
                underline="none"
                sx={
                  pathname === "/announcements"
                    ? style.navItemSelected
                    : style.navItem
                }
              >
                Announcements
              </Link>
              <IconButton
                color="primary"
                sx={style.themeIcon}
                onClick={toggleThemeFunc}
              >
                {ui.isDarkMode ? (
                  <LightIcon style={{ height: 20, color: "inherit" }} />
                ) : (
                  <DarkIcon style={{ height: 20, color: "inherit" }} />
                )}
              </IconButton>
            </Box>
            <Box sx={style.menuHamburgerContainer}>
              <IconButton color="primary" onClick={toggleDrawer}>
                {state.showDrawer ? (
                  <CloseIcon style={{ width: 20, color: "inherit" }} />
                ) : (
                  <MenuIcon style={{ width: 20, color: "inherit" }} />
                )}
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              ...style.drawerItems,
              display: { xs: state.showDrawer ? "flex" : "none", md: "none" },
            }}
          >
            <Link
              component={RLink}
              to="/"
              underline="none"
              sx={pathname === "/" ? style.navItemSelected : style.navItem}
            >
              Home
            </Link>
            <Link
              component={RLink}
              to="/cite-faculty"
              underline="none"
              sx={
                pathname === "/cite-faculty"
                  ? style.navItemSelected
                  : style.navItem
              }
            >
              CITE Faculty
            </Link>
            <Link
              component={RLink}
              to="/downloadable-forms"
              underline="none"
              sx={
                pathname === "/downloadable-forms"
                  ? style.navItemSelected
                  : style.navItem
              }
            >
              Downloadable Forms
            </Link>
            <Link
              component={RLink}
              to="/announcements"
              underline="none"
              sx={
                pathname === "/announcements"
                  ? style.navItemSelected
                  : style.navItem
              }
            >
              Announcements
            </Link>

            <IconButton
              color="primary"
              sx={style.themeIcon}
              onClick={toggleThemeFunc}
            >
              {ui.isDarkMode ? (
                <LightIcon style={{ height: 20, color: "inherit" }} />
              ) : (
                <DarkIcon style={{ height: 20, color: "inherit" }} />
              )}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Slide>
  );
}

import darkScrollbar from "@mui/material/darkScrollbar";

const theme = (isDarkMode) => ({
  palette: {
    mode: isDarkMode ? "dark" : "light",
    primary: {
      main: "#BD400F",
    },
    secondary: {
      main: "#EDBD42",
    },
    error: {
      main: "#f44336",
    },
    footer: "#51050F",
  },
  components: {
    typography: {
      fontFamily: [
        "Open Sans",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: isDarkMode ? darkScrollbar() : null,
      },
    },
  },
});

export default theme;

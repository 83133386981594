const globalStyle = {
  h1: {
    fontSize: 32,
    fontWeight: 500,
  },
  h2: {
    fontSize: 24,
    fontWeight: 500,
  },
  h3: {
    fontSize: 18,
    fontWeight: 500,
  },
  h4: {
    fontSize: 14,
    fontWeight: 500,
  },
  h5: {
    fontSize: 14,
    fontWeight: 400,
  },
  sh: {
    fontSize: 12,
    fontWeight: 500,
  },
  p: {
    fontSize: 12,
    fontWeight: 400,
  },
  flexGrow: {
    flexGrow: 1,
  },
  removeShadow: {
    boxShadow: "none",
  },
  marginLeft3: {
    marginLeft: 3,
  },
  itemCenter: {
    display: "flex",
    justifyContent: "center",
  },
  itemCenterCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

export default globalStyle;

import React from "react";
import { Helmet } from "react-helmet";

import { Box, Typography } from "@mui/material";
import globalStyle from "../utils/globalStyle";
import images from "../images/facultyImages";

import Header from "../components/Header";
import Footer from "../components/Footer";

import HeadBox from "../components/HeadBox";
import Faculty from "../components/Faculty";
import ScrollToTopButton from "../components/ScrollToTopButton";

import MyPicture from "../assets/img/faculty/itsjnathan.png";

const style = {
  ...globalStyle,
  section1: {
    padding: "64px 3px",
    backgroundColor: (theme) => theme.palette.primary.dark,
  },
  headerText1: {
    ...globalStyle.h1,
    textAlign: "center",
    fontWeight: 700,
    color: (theme) => theme.palette.common.white,
  },
  headerText2: {
    ...globalStyle.h2,
    textAlign: "center",
    color: (theme) => theme.palette.common.white,
    marginTop: 2,
  },
  section2: {
    padding: "24px 3px",
  },
};
export default function CITEFaculty() {
  return (
    <Box>
      <Helmet>
        <title>BulSU - CITE - Faculty</title>
        <meta
          name="description"
          content="COLLEGE OF INFORMATION TECHNOLOGY AND ENGINEERING... CITE Faculty Members"
        />
        <meta property="og:title" content="CITE Faculty" />
        <meta property="og:image" content={MyPicture} />
      </Helmet>
      <Header />

      <Box sx={style.section1}>
        <Typography variant="h1" sx={style.headerText1}>
          College of Information Technology and Engineering
        </Typography>
        <Typography variant="h2" sx={style.headerText2}>
          S.Y. 2021 - 2022
        </Typography>
      </Box>

      <Box sx={style.section2}>
        {images.cite_head.map((data, i) => (
          <HeadBox row={i} data={data} key={i} />
        ))}
      </Box>
      <Box sx={style.section1}>
        <Typography variant="h1" sx={style.headerText1}>
          Information Technology
        </Typography>
      </Box>
      <Box sx={style.section2}>
        <Faculty data={images.cite_it_faculty} desc="" />
      </Box>
      <Box sx={style.section1}>
        <Typography variant="h1" sx={style.headerText1}>
          Engineering
        </Typography>
      </Box>

      <Box sx={style.section2}>
        <Faculty data={images.cite_engineering} desc="" />
      </Box>

      <Box sx={style.section1}>
        <Typography variant="h1" sx={style.headerText1}>
          General Education Subjects
        </Typography>
      </Box>

      <Box sx={style.section2}>
        <Faculty data={images.cite_gen_ed} desc="" />
      </Box>

      <Box sx={style.section1}>
        <Typography variant="h1" sx={style.headerText1}>
          FACULTY MEMBERS FROM NAPA
        </Typography>
      </Box>

      <Box sx={style.section2}>
        <Faculty data={images.cite_napa} desc="" />
      </Box>

      <ScrollToTopButton />
      <Footer />
    </Box>
  );
}

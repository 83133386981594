import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import globalStyle from "../utils/globalStyle";

const style = {
  ...globalStyle,
  root: {
    padding: "32px 5px",
  },
  image: {
    height: {
      xs: 300,
      sm: 350,
      md: 400,
    },
  
    // objectFit: "cover",
    alignSelf: "center",
  },
  profName: {
    fontSize: {
      xs: globalStyle.h4.fontSize,
      sm: globalStyle.h3.fontSize,
      md: globalStyle.h2.fontSize,
    },
    marginTop: 1,
    fontWeight: 600,
  },
  profDesc: {
    fontSize: {
      xs: globalStyle.h5.fontSize,
      sm: globalStyle.h5.fontSize,
      md: globalStyle.h4.fontSize,
    },
    color: (theme) => theme.palette.primary.main,
  },
};

export default function Faculty({ data, desc }) {
  return (
    <Box sx={style.root}>
      <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
        {data.map((row, i) => (
          <Grid md item sx={style.itemCenterCol} key={i}>
            <Box component="img" src={row.img} alt="dean" sx={style.image} />
            <Typography variant="h1" sx={style.profName} textAlign="center">
              {row.name}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={style.profDesc}
              textAlign="center"
            >
              {desc}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

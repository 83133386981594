import React from "react";
import { Helmet } from "react-helmet";
import { Box, Typography } from "@mui/material";
import globalStyle from "../utils/globalStyle";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton";

const style = {
  ...globalStyle,
  section1: {
    padding: "64px 3px",
    backgroundColor: (theme) => theme.palette.primary.dark,
  },
  headerText1: {
    ...globalStyle.h1,
    textAlign: "center",
    fontWeight: 700,
    color: (theme) => theme.palette.common.white,
  },
  headerText2: {
    ...globalStyle.h2,
    textAlign: "center",
    color: (theme) => theme.palette.common.white,
    marginTop: 2,
  },
  section2: {
    padding: "200px 3px",
    display: "flex",
    justifyContent: "center",
  },
  text2: {
    textAlign: "center",
    fontSize: {
      xs: 20,
      sm: 24,
      md: 32,
    },
    fontWeight: 500,
  },
};

export default function Announcements() {
  return (
    <Box>
      <Helmet>
        <title>BulSU - CITE - Announcements</title>
        <meta
          name="description"
          content="COLLEGE OF INFORMATION TECHNOLOGY AND ENGINEERING ... Announcements"
        />
      </Helmet>
      <Header />
      <Box sx={style.section1}>
        <Typography variant="h1" sx={style.headerText1}>
          Announcements
        </Typography>
      </Box>
      <Box sx={style.section2}>
        <Typography variant="h2" sx={style.text2}>
          No recent announcement available.
        </Typography>
      </Box>

      <ScrollToTopButton />
      <Footer />
    </Box>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Link,
} from "@mui/material";
import globalStyle from "../utils/globalStyle";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton";

const style = {
  ...globalStyle,
  section1: {
    padding: "64px 3px",
    backgroundColor: (theme) => theme.palette.primary.dark,
  },
  headerText1: {
    ...globalStyle.h1,
    textAlign: "center",
    fontWeight: 700,
    color: (theme) => theme.palette.common.white,
  },
  headerText2: {
    ...globalStyle.h2,
    textAlign: "center",
    color: (theme) => theme.palette.common.white,
    marginTop: 2,
  },
  section2: {
    padding: "64px 3px",
    display: "flex",
    justifyContent: "center",
  },
  tableContainer: {
    maxWidth: "90%",
  },
  table: {
    minWidth: 650,
  },
};

const files = [
  {
    name: "Adding/Changing Form",
    type: "Excel Document",
    size: "99.27 KB",
    link: "https://www.bulsu.edu.ph/downloads/files/Adding-Changing-Form-External-Campus.xlsx",
  },
  {
    name: "Dropping Form",
    type: "Word Document",
    size: "222.97 KB",
    link: "https://www.bulsu.edu.ph/downloads/files/Revision-1-Dropping-Form.docx",
  },
  {
    name: "Request for Petition",
    type: "Word Document",
    size: "101.03 KB",
    link: "https://www.bulsu.edu.ph/downloads/files/Request-for-Petition-External-Campus.docx",
  },
  {
    name: "Student Completion Form",
    type: "Word Document",
    size: "50.59 KB",
    link: "https://www.bulsu.edu.ph/downloads/files/Completion_Form.docx",
  },
  {
    name: "Student Handbook",
    type: "PDF File",
    size: "11.23 MB",
    link: "https://www.bulsu.edu.ph/downloads/files/student_handbook.pdf",
  },
];
export default function DownloadableForms() {
  return (
    <Box>
      <Helmet>
        <title>BulSU - CITE - Downloadable Forms</title>
        <meta
          name="description"
          content="COLLEGE OF INFORMATION TECHNOLOGY AND ENGINEERING ... Downloadable Forms ... Download for Students"
        />
      </Helmet>
      <Header />
      <Box sx={style.section1}>
        <Typography variant="h1" sx={style.headerText1}>
          Downloads for students
        </Typography>
      </Box>
      <Box sx={style.section2}>
        <TableContainer component={Paper} sx={style.tableContainer}>
          <Table sx={style.table}>
            <TableHead sx={{ th: { fontWeight: 700 } }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Download Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontWeight: 700,
                    }}
                  >
                    <Link
                      underline="none"
                      href={row.link}
                      target="_blank"
                      rel="noopener"
                    >
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.size}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      component={Link}
                      href={row.link}
                      target="_blank"
                      rel="noopener"
                    >
                      Download
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <ScrollToTopButton />
      <Footer />
    </Box>
  );
}
